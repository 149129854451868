<template>
  <TnInput
    v-model="password"
    :label="label"
    :placeholder="label"
    required
    :error="!!error"
    :input-type="showPassword ? 'text' : 'password'"
    :show-asterisk="false"
  >
    <template #right>
      <button
        @click="togglePasswordVisibility"
        type="button"
        class="focus:outline-primary flex items-center justify-center"
      >
        <UIcon
          :name="
            showPassword
              ? 'i-material-symbols-visibility-outline'
              : 'i-material-symbols-visibility-off-outline'
          "
          class="text-tnNeutral-400 text-[25px] font-normal"
        />
      </button>
    </template>
  </TnInput>
</template>

<script setup lang="ts">
// Props for the component
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
    default: () => 'Password',
  },
  error: {
    type: Boolean,
    default: false,
  },
});

// Emit events
const emit = defineEmits(['update:modelValue']);

// Local state for the password and visibility toggle
const password = ref(props.modelValue);
const showPassword = ref(false);

// Watch for changes to modelValue and update local password state
watch(
  () => props.modelValue,
  (newValue) => {
    password.value = newValue;
  }
);

// Watch for changes to the local password state and emit updates
watch(password, (newValue) => {
  emit('update:modelValue', newValue);
});

// Toggle password visibility
const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};
</script>
